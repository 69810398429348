import './App.css';
import {useState} from "react";
import extractor from "./extractor";

function App() {

    const [amazonUrl, setAmazonUrl] = useState('');
    const [productData, setProductData] = useState(null);

    const getAmazonData = async () => {
        const res = await fetch('https://amazon.avashops.ir/api/' + amazonUrl.split('https://www.amazon.com/').join('').split('https://amazon.com/').join('').split('amazon.com/').join(''));
        const response = await res.text();
        const productInfo = extractor(response);
        console.log(productInfo)
        setProductData(productInfo)
    }


  return (
    <div className="App">
      <header className="App-header">
          <h2 className={'App-logo'}>Provide a amazon product link</h2>
          <input placeholder={'amazon link'} onChange={e => setAmazonUrl(e.target.value)}/>
          <button onClick={getAmazonData}>Submit</button>
          {!!productData && (
              <div>
                  <h1>Product Information</h1>
                  <p><strong>Price:</strong> {productData?.price}</p>
                  {productData?.dimensions.length && productData?.dimensions.width && productData?.dimensions.height ? (
                      <div>
                          <p>Length: {productData.dimensions.length} {productData.dimensions.unit}</p>
                          <p>Width: {productData.dimensions.width} {productData.dimensions.unit}</p>
                          <p>Height: {productData.dimensions.height} {productData.dimensions.unit}</p>
                      </div>
                  ) : (
                      <p>{productData?.dimensions?.dimensions}</p>
                  )}
                  <p><strong>Weight:</strong> {productData?.weight}</p>
                  <p><strong>Shipping Costs:</strong> {productData?.shipping}</p>
                  <img alt={'product image'} src={productData?.imageUrl}/>
              </div>
          )}
      </header>
    </div>
  );
}

export default App;
