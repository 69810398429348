// import cheerio from 'cheerio';
//
//
// const extractDimensions = (dimensionsString) => {
//     // Example dimensionsString: "10 x 5 x 3 inches"
//     const dimensionsPatterns = [
//         /(\d+(\.\d+)?)\s*[x\*]\s*(\d+(\.\d+)?)\s*[x\*]\s*(\d+(\.\d+)?)(\s*("|inches|inch|in|cm|mm|ft|m))/i,
//         /Dimensions:\s*(\d+(\.\d+)?)\s*[x\*]\s*(\d+(\.\d+)?)\s*[x\*]\s*(\d+(\.\d+)?)(\s*("|inches|inch|in|cm|mm|ft|m))/i,
//         /(\d+(\.\d+)?)\s*(inches|inch|in|cm|mm|ft|m)\s*[x\*]\s*(\d+(\.\d+)?)\s*(inches|inch|in|cm|mm|ft|m)\s*[x\*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
//         /(\d+(\.\d+)?)(\s*("|inches|inch|in|cm|mm|ft|m))\s*by\s*(\d+(\.\d+)?)(\s*("|inches|inch|in|cm|mm|ft|m))\s*by\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i
//     ];
//
//     for (let pattern of dimensionsPatterns) {
//         const match = pattern.exec(dimensionsString);
//         if (match) {
//             return {
//                 dimensionsString: dimensionsString,
//                 length: parseFloat(match[1]),
//                 width: parseFloat(match[3]),
//                 height: parseFloat(match[5]),
//                 unit: match[6].toLowerCase()
//             };
//         }
//     }
//
//     return null;
// };
//
// const extractProductInfo = (htmlString) => {
//     const $ = cheerio.load(htmlString);
//
//     // Extract price
//     const price = $('#priceblock_ourprice').text() ||
//         $('#priceblock_dealprice').text() ||
//         $('.a-price .a-offscreen').first().text() ||
//         $('.aok-offscreen').text() ||
//         $('#priceblock_saleprice').text() ||
//         $('#priceblock_vatprice').text() ||
//         $('.priceBlockBuyingPriceString').text() ||
//         $('.priceBlockSavingsString').text() ||
//         $('#priceblock_snsprice_Based').text() ||
//         'Price not available';
//
//     // Extract dimensions
//     const dimensionsString = $('tr:contains("Product Dimensions") td').last().text().trim() ||
//         $('tr:contains("Package Dimensions") td').last().text().trim() ||
//         $('tr:contains("Dimensions") td').last().text().trim() ||
//         $('#detailBulletsWrapper_feature_div li:contains("Dimensions") span.a-text-bold').parent().next().text().trim() ||
//         $('#detailBullets_feature_div li:contains("Dimensions") span.a-text-bold').parent().next().text().trim() ||
//         $('#productDetails_techSpec_section_1 td:contains("Dimensions") + td').text().trim() ||
//         $('.techD .prodDetSectionEntry:contains("Dimensions")').next().text().trim() ||
//         'Dimensions not available';
//
//     const dimensions = extractDimensions(dimensionsString) || { dimensions: dimensionsString };
//
//
//     // Extract weight
//     const weight = $('tr:contains("Item Weight") td').last().text() ||
//         $('tr:contains("Shipping Weight") td').last().text() ||
//         $('tr:contains("Weight") td').last().text() ||
//         $('#detailBulletsWrapper_feature_div li:contains("Weight") span.a-text-bold').parent().next().text() ||
//         $('#productDetails_techSpec_section_1 td:contains("Weight") + td').text() ||
//         $('.techD .prodDetSectionEntry:contains("Weight")').next().text() ||
//         'Weight not available';
//
//     // Extract shipping costs
//     const shipping = $('#ourprice_shippingmessage .a-color-secondary').text() ||
//         $('#price-shipping-message').text() ||
//         $('#price-info-supersaver').text() ||
//         $('.shipping3P').text() ||
//         $('.a-section .a-spacing-mini .shipping-information').text() ||
//         'Shipping information not available';
//
//     return {
//         price: price.trim(),
//         dimensions,
//         weight: weight.trim(),
//         shipping: shipping.trim(),
//     };
// };
//
// export default extractProductInfo;
//

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// import cheerio from 'cheerio';
//
// const extractDimensions = (dimensionsString) => {
//     const dimensionsPatterns = [
//         /(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
//         /Dimensions:\s*(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
//         /(\d+(\.\d+)?)\s*(inches|inch|in|cm|mm|ft|m)\s*[xX*]\s*(\d+(\.\d+)?)\s*(inches|inch|in|cm|mm|ft|m)\s*[xX*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
//         /(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))\s*by\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))\s*by\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i
//     ];
//
//     for (let pattern of dimensionsPatterns) {
//         const match = pattern.exec(dimensionsString);
//         if (match) {
//             return {
//                 length: parseFloat(match[1]),
//                 width: parseFloat(match[3]),
//                 height: parseFloat(match[5]),
//                 unit: match[7] ? match[7].toLowerCase() : match[8].toLowerCase()
//             };
//         }
//     }
//
//     return null;
// };
//
// const extractProductInfo = (htmlString) => {
//     const $ = cheerio.load(htmlString);
//
//     // Extract price
//     const price = $('#priceblock_ourprice, #priceblock_dealprice, .a-price .a-offscreen, .aok-offscreen, #priceblock_saleprice, #priceblock_vatprice, .priceBlockBuyingPriceString, .priceBlockSavingsString, #priceblock_snsprice_Based').first().text().trim() || 'Price not available';
//
//     // Extract dimensions
//     const dimensionsString = $('tr:contains("Product Dimensions") td, tr:contains("Package Dimensions") td, tr:contains("Dimensions") td, #detailBulletsWrapper_feature_div li:contains("Dimensions") span.a-text-bold, #detailBullets_feature_div li:contains("Dimensions") span.a-text-bold, #productDetails_techSpec_section_1 td:contains("Dimensions") + td, .techD .prodDetSectionEntry:contains("Dimensions")').last().text().trim() || 'Dimensions not available';
//
//     const dimensions = extractDimensions(dimensionsString) || { dimensions: dimensionsString };
//
//     // Extract weight
//     const weight = $('tr:contains("Item Weight") td, tr:contains("Shipping Weight") td, tr:contains("Weight") td, #detailBulletsWrapper_feature_div li:contains("Weight") span.a-text-bold, #productDetails_techSpec_section_1 td:contains("Weight") + td, .techD .prodDetSectionEntry:contains("Weight")').last().text().trim() || 'Weight not available';
//
//     // Extract shipping costs
//     const shipping = $('#ourprice_shippingmessage .a-color-secondary, #price-shipping-message, #price-info-supersaver, .shipping3P, .a-section .a-spacing-mini .shipping-information').text().trim() || 'Shipping information not available';
//
//     return {
//         price,
//         dimensions,
//         weight,
//         shipping,
//     };
// };
//
// export default extractProductInfo;
///////////////////////////////////////////2222222222222222222222222222222222222222222222222222222222222222222222
//
// import cheerio from 'cheerio';
//
// const extractDimensions = (dimensionsString) => {
//     const dimensionsPatterns = [
//         /(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
//         /Dimensions:\s*(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
//         /(\d+(\.\d+)?)\s*(inches|inch|in|cm|mm|ft|m)\s*[xX*]\s*(\d+(\.\d+)?)\s*(inches|inch|in|cm|mm|ft|m)\s*[xX*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
//         /(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))\s*by\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))\s*by\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i
//     ];
//
//     for (let pattern of dimensionsPatterns) {
//         const match = pattern.exec(dimensionsString);
//         if (match) {
//             return {
//                 length: parseFloat(match[1]),
//                 width: parseFloat(match[3]),
//                 height: parseFloat(match[5]),
//                 unit: match[7] ? match[7].toLowerCase() : match[8].toLowerCase()
//             };
//         }
//     }
//
//     return null;
// };
//
// const extractProductInfo = (htmlString) => {
//     const $ = cheerio.load(htmlString);
//
//     // Extract price
//     const price = $('#priceblock_ourprice, #priceblock_dealprice, .a-price .a-offscreen, .aok-offscreen, #priceblock_saleprice, #priceblock_vatprice, .priceBlockBuyingPriceString, .priceBlockSavingsString, #priceblock_snsprice_Based').first().text().trim() || 'Price not available';
//
//     // Extract dimensions
//     const dimensionsString = $('tr:contains("Product Dimensions") td, tr:contains("Package Dimensions") td, tr:contains("Dimensions") td, #detailBulletsWrapper_feature_div li:contains("Dimensions") span, #detailBullets_feature_div li:contains("Dimensions") span, #productDetails_techSpec_section_1 td:contains("Dimensions") + td, .techD .prodDetSectionEntry:contains("Dimensions")').last().text().trim() || 'Dimensions not available';
//     const dimensions = extractDimensions(dimensionsString) || { dimensions: dimensionsString };
//
//     // Extract weight
//     const weight = $('tr:contains("Item Weight") td, tr:contains("Shipping Weight") td, tr:contains("Weight") td, #detailBulletsWrapper_feature_div li:contains("Weight") span, #productDetails_techSpec_section_1 td:contains("Weight") + td, .techD .prodDetSectionEntry:contains("Weight")').last().text().trim() || 'Weight not available';
//
//     // Extract shipping costs
//     const shipping = $('#ourprice_shippingmessage .a-color-secondary, #price-shipping-message, #price-info-supersaver, .shipping3P, .a-section .a-spacing-mini .shipping-information').text().trim() || 'Shipping information not available';
//
//     return {
//         price,
//         dimensions,
//         weight,
//         shipping,
//     };
// };
//
// export default extractProductInfo;
//

//////////////////////////////////////////////////////////////////////////////////////////////////////////

















// todo this one is the best
// import cheerio from 'cheerio';
//
// const extractDimensions = (dimensionsString) => {
//     const dimensionsPatterns = [
//         /(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
//         /Dimensions:\s*(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
//         /(\d+(\.\d+)?)\s*(inches|inch|in|cm|mm|ft|m)\s*[xX*]\s*(\d+(\.\d+)?)\s*(inches|inch|in|cm|mm|ft|m)\s*[xX*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
//         /(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))\s*by\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))\s*by\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i
//     ];
//
//     for (let pattern of dimensionsPatterns) {
//         const match = pattern.exec(dimensionsString);
//         if (match) {
//             return {
//                 length: parseFloat(match[1]),
//                 width: parseFloat(match[3]),
//                 height: parseFloat(match[5]),
//                 unit: match[7] ? match[7].toLowerCase() : match[8].toLowerCase()
//             };
//         }
//     }
//
//     return null;
// };
//
// const extractProductInfo = (htmlString) => {
//     const $ = cheerio.load(htmlString);
//
//     // Extract price
//     const price = $('#priceblock_ourprice, #priceblock_dealprice, .a-price .a-offscreen, .aok-offscreen, #priceblock_saleprice, #priceblock_vatprice, .priceBlockBuyingPriceString, .priceBlockSavingsString, #priceblock_snsprice_Based').first().text().trim() || 'Price not available';
//
//     // Extract dimensions
//     const dimensionsString = $('tr:contains("Product Dimensions") td, tr:contains("Package Dimensions") td, tr:contains("Dimensions") td, #detailBulletsWrapper_feature_div li:contains("Dimensions") span, #detailBullets_feature_div li:contains("Dimensions") span, #productDetails_techSpec_section_1 td:contains("Dimensions") + td, .techD .prodDetSectionEntry:contains("Dimensions")').last().text().trim() || 'Dimensions not available';
//     const dimensions = extractDimensions(dimensionsString) || { dimensions: dimensionsString };
//
//     // Extract weight
//     const weight = $('tr:contains("Item Weight") td, tr:contains("Shipping Weight") td, tr:contains("Weight") td, #detailBulletsWrapper_feature_div li:contains("Weight") span, #productDetails_techSpec_section_1 td:contains("Weight") + td, .techD .prodDetSectionEntry:contains("Weight")').last().text().trim() || 'Weight not available';
//
//     // Extract shipping costs
//     let shipping = $('#ourprice_shippingmessage .a-color-secondary, #price-shipping-message, #price-info-supersaver, .shipping3P, .a-section .a-spacing-mini .shipping-information, .delivery-message, #priceblock_ourprice_shippingmessage, #price-shipping-message .a-color-secondary').text().trim();
//
//     // Additional shipping cost extraction from popover
//     if (!shipping || shipping === 'Shipping information not available') {
//         const popoverShipping = $('#a-popover-agShipMsgPopover .a-lineitem').find('tr:contains("AmazonGlobal Shipping")').find('.a-span2 .a-size-base.a-color-base').first().text().trim();
//         if (popoverShipping) {
//             shipping = popoverShipping;
//         }
//     }
//
//     shipping = shipping || 'Shipping information not available';
//
//     return {
//         price,
//         dimensions,
//         weight,
//         shipping,
//     };
// };
//
// export default extractProductInfo;











// todo this is the best one with image
import cheerio from 'cheerio';

const extractDimensions = (dimensionsString) => {
    const dimensionsPatterns = [
        /(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
        /Dimensions:\s*(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)\s*[xX*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
        /(\d+(\.\d+)?)\s*(inches|inch|in|cm|mm|ft|m)\s*[xX*]\s*(\d+(\.\d+)?)\s*(inches|inch|in|cm|mm|ft|m)\s*[xX*]\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i,
        /(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))\s*by\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))\s*by\s*(\d+(\.\d+)?)(\s*(inches|inch|in|cm|mm|ft|m))/i
    ];

    for (let pattern of dimensionsPatterns) {
        const match = pattern.exec(dimensionsString);
        if (match) {
            return {
                length: parseFloat(match[1]),
                width: parseFloat(match[3]),
                height: parseFloat(match[5]),
                unit: match[7] ? match[7].toLowerCase() : match[8].toLowerCase()
            };
        }
    }

    return null;
};

const extractProductInfo = (htmlString) => {
    const $ = cheerio.load(htmlString);

    // Extract price
    const price = $('#priceblock_ourprice, #priceblock_dealprice, .a-price .a-offscreen, .aok-offscreen, #priceblock_saleprice, #priceblock_vatprice, .priceBlockBuyingPriceString, .priceBlockSavingsString, #priceblock_snsprice_Based').first().text().trim() || 'Price not available';

    // Extract dimensions
    const dimensionsString = $('tr:contains("Product Dimensions") td, tr:contains("Package Dimensions") td, tr:contains("Dimensions") td, #detailBulletsWrapper_feature_div li:contains("Dimensions") span, #detailBullets_feature_div li:contains("Dimensions") span, #productDetails_techSpec_section_1 td:contains("Dimensions") + td, .techD .prodDetSectionEntry:contains("Dimensions")').last().text().trim() || 'Dimensions not available';
    const dimensions = extractDimensions(dimensionsString) || { dimensions: dimensionsString };

    // Extract weight
    const weight = $('tr:contains("Item Weight") td, tr:contains("Shipping Weight") td, tr:contains("Weight") td, #detailBulletsWrapper_feature_div li:contains("Weight") span, #productDetails_techSpec_section_1 td:contains("Weight") + td, .techD .prodDetSectionEntry:contains("Weight")').last().text().trim() || 'Weight not available';

    // Extract shipping costs
    let shipping = $('#ourprice_shippingmessage .a-color-secondary, #price-shipping-message, #price-info-supersaver, .shipping3P, .a-section .a-spacing-mini .shipping-information, .delivery-message, #priceblock_ourprice_shippingmessage, #price-shipping-message .a-color-secondary').text().trim();

    // Additional shipping cost extraction from popover
    if (!shipping || shipping === 'Shipping information not available') {
        const popoverShipping = $('#a-popover-agShipMsgPopover .a-lineitem').find('tr:contains("AmazonGlobal Shipping")').find('.a-span2 .a-size-base.a-color-base').first().text().trim();
        if (popoverShipping) {
            shipping = popoverShipping;
        }
    }

    shipping = shipping || 'Shipping information not available';

    // Extract product image URL
    const imageUrl = $('#imgTagWrapperId img').attr('src') || 'Image URL not available';

    return {
        price,
        dimensions,
        weight,
        shipping,
        imageUrl,
    };
};

export default extractProductInfo;
